/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes achats';
export const txtnombredoc = 'achat';
export const txtnombredocpluriel = 'achats';
export const titleAlveole = 'Mes achats';

// TODO arriver a faire fonctionner
export const alveoleLogoSvg = "./../images/icon-alv-mesconsos-blanc.svg";

/************************ DeV ******************************************************/
export const zAPIdocuments = process.env.REACT_APP_API_DOCUMENTS;
export const zUrlBack = process.env.REACT_APP_API_MES_ACHATS;
export const zUrlBackNotifications = process.env.REACT_APP_API_SERVICENOTIFICATION;

/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000"
// export const zAPIdocuments = "https://localhost:8000/consos";
// export const zUrlBackNotifications = "https://localhost:8001";

/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLHome = process.env.REACT_APP_HOME_CUSTOMER;
export const zURLProfile = process.env.REACT_APP_PROFILE;


